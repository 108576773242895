<template>
<div
    v-if="value.length"
    class="indicators"
>
    <div
        v-for="(v, i) in value"
        :key="i"
        :class="['indicator', `indicator--${v}`]"
    >{{value}}</div>
</div>
</template>

<script>

export default {
    name: 'indicators',
    props: {
        value: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}
.indicators {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    .indicator {
        height: 14px;
        width: 14px;
        border-radius: 50%;
        &--1 {
            background-color: #0DFB50;
        }
        &--2 {
            background-color: #FF0000;
        }
        &--3 {
            background-color: #A3A4A3;
        }
        &--4 {
            background-color: #5ED1F6;
        }
    }
}
</style>
