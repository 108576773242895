import SubHeader from "@/components/SubHeader";
import axios from "@/axios";
import DropdownIcon from "@/assets/icons/email-admin/icon-dropdown.svg";
import Search from "@/assets/icons/email-admin/icon-search.svg";
import FolderIcon from "@/assets/icons/email-admin/icon-folder.svg";
import TextTemplate from "@/assets/icons/email-admin/icon-text-temp.svg";
import TemplatesDropDown from "@/components/templates-treeview/templates-dropdown.vue";
import { checkPermission } from "@shared/utils/functions"
let result = [];

export default {
    name: "default-template-table",
    components: {
        SubHeader,
        DropdownIcon,
        Search,
        FolderIcon,
        TextTemplate,
        TemplatesDropDown
    },
    data() {
        return {
            columns:[
                {
                    label: "Action",
                    field: "action",
                    filterOptions: {
                        enabled: false
                    },
                    sortable:false
                },
                {
                    label: "Fields Required",
                    field: "placeholder_list",
                    filterOptions: {
                        enabled: false
                    },
                    sortable:false
                },
                {
                    label: "Default Template/Sequence",
                    field: "default_template_sequence",
                    filterOptions: {
                        enabled: false
                    },
                    sortable:false
                },
            ],
            actionList:[
               
            ],
            allTemplates:[],
            allSequences: [],
            showDropDown: true,
            disabledDropdown: false,
            sendoptions: null,
            right: "",
            customStyle: "",
            searchQuery: "",
            disableBtn: true,
            openFolder: null,
        }
       
    },
    computed: {
        getRows() {
            return this.actionList
        },
        filteredTemplates() {
            if (this.searchQuery) {
                result = []
                const searchList = this.getSearchedContent(this.searchQuery, this.allTemplates)
                return searchList;
            } else {
                return this.allTemplates;
            }
        },
        filteredSequences() {
            if (this.searchQuery) {
                result = []
                const searchList = this.getSearchedContent(this.searchQuery, this.allSequences)
                return searchList;
            } else {
                return this.allSequences;
            }
        },
        
    },
    methods: {
        checkPermission,
        async fetchTemplateList() {
            this.isLoading = true;
            try {
                const url = `/email-template/list?external_id=${this.$store.getters.getTenantId}`;
                const result = await axios.get(url, { params: {...this.sortParams } });
                let data = result?.data?.content;
                this.allTemplates = data;
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
            } finally {
                this.isLoading = false;
            }
        },
        
        getSearchedContent(searchQuery,arr) {
            // result = []
           let content = arr.find((item) => item.name?.toLowerCase()?.includes(searchQuery.toLowerCase()))
            if(content){
                result.push(content);
            }
            arr.forEach(el => {
                if(el.content && el.content.length >0){
                    return this.getSearchedContent(searchQuery,el.content);
               }
           });
         return result
            
        },
        getFilteredId(id,template) {
            let match = template.find((item) => item.id == id);
            if (match) {
            this.actionList.map(el => {
                     if(el.sequence_template_master_id == match.id)
                        el.name = match.name
                })
                return match;
            }
            else {
                template.forEach((item) => {
                    // console.log(item.content);
                    if (item?.content?.length)
                        return this.getFilteredId(id, item.content);
                })
            }
        },
        // getTemplateId(id,arr,type) {
        //     console.log(id, arr, type);
        //     if(arr) {
        //     let selectedResult = this.getFilteredId(id,arr)
        //      console.log(selectedResult);
        //     }
        //     // if(selectedResult) { return selectedResult}
        //     // else {
        //     //         if(type == "sequence")
        //     //             return "Select a Sequence"
        //     //         else if(type == "template")
        //     //             return "Select a Template"
        //     //         else return type
        //     // }     
        // },
        async defaultTemplates() {
            try {
                const url = '/tenant/default-emails/all'
                const result = await axios.get(url)
                this.actionList = result.data
                if(this.actionList){
                 this.actionList.map(el => {
                    if(el.type==="template")
                    this.getFilteredId(el.sequence_template_master_id,this.allTemplates,el.type)
                    if(el.type==="sequence")
                    this.getFilteredId(el.sequence_template_master_id,this.allSequences,el.type)
                 })
                }
            }
            catch(error) {
                this.$toast.error(error.response?.data?.detail)
            }
        },
        async fetchSequenceList() {
            this.isLoading = true
            try {
              const url = `/email-sequence/list?external_id=${this.$store.getters.getTenantId}`;
              const result = await axios.get(url);
      
              let data = result?.data?.content;
              this.allSequences = data;
            } catch (error) {
              this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
            }
            this.isLoading = false
      
        },
        async setTemplate(detail,action,type) {
            if(!this.checkPermission('defaultEmailSequence.edit')) {
                this.$toast.error("You don't have edit permission!")
                return;
            }
            let payload = {
                action: action,
                sequence_template_master_id: detail.id,
                type: type
            }
            try
            {
                const url = '/tenant/default-emails/all'
                const result = await axios.post(url,payload)
                if(result.data == "updated" || result.data == "created") {
                    this.$toast.success("Default Template/Sequence Updated Successfully")
                    this.defaultTemplates();
                    this.sendoptions = null;
                    this.searchQuery = "";
                    this.openFolder = null;
                }
            }
            catch(e) {
                if(e.response?.data?.detail) this.$toast.error(e.response?.data?.detail)
                else this.$toast.error("Something went wrong. Please try again");
            }
        },
        popupbtn(id) {
            if (!this.disabledDropdown) {
                this.sendoptions = id;
            }
            this.searchQuery = "";
            this.openFolder = null;
        },
        handleBtnClick(id) {
            if (this.disableBtn) this.popupbtn(id);
            else this.handleSubmit();
        },
        handleFolderClick(id) {
            if(this.openFolder == id) this.openFolder = null;
            else this.openFolder = id
        },
        onOutsideClick() {
            if(this.sendoptions)
                this.sendoptions = null;
            if(this.openFolder)
                this.openFolder = null;
        },
        async handleSubmit() {
            this.sendoptions = null;
            this.$emit("onSave");
        },
    },
    async mounted() {
        await this.fetchTemplateList();
        await this.fetchSequenceList();
        await this.defaultTemplates();
    }
}