// import axios from "@/axios";
import CustomInput from "@/components/custom-input";
import CloseIcon from "@/assets/icons/email-admin/icon-crossbt.svg";
import MenuButton from "@/components/menu-button";
import TextTemplate from "@/assets/icons/email-admin/icon-text-temp.svg";

export default {
  name: "renameFolder",
  components: {
    CustomInput,
    CloseIcon,
    MenuButton,
    texttemplate: TextTemplate,
  },
  data() {
    return {
      formData: {
        id: "",
        templateName: "",
        path: "/",
      },
    };
  },
  props: {
    open: Boolean,
    populateData: Object,
    toggleModal: Function,
    handleSave: Function,
    isCreateSequenceModal: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: "",
    },
    directoryToastMessage: null, 
  },
  methods: {
  },
  watch: {
    open: function (newVal) {
      if (newVal) {
        this.$refs.renameFolderModal.showModal();
      } else {
        this.$refs.renameFolderModal.close();
      }
    },
    populateData: function (newVal) {
      if (newVal) {
        this.formData = newVal;
        let path = this.formData?.path || "";
        let arr = path?.split("/");
        if (arr?.length > 1)
          arr.pop();
        this.formData.path = arr.join("/") || "";
      }
    },
  },
};
