import { render, staticRenderFns } from "./email-template.html?vue&type=template&id=310f64c3&scoped=true&external"
import script from "./email-template.js?vue&type=script&lang=js&external"
export * from "./email-template.js?vue&type=script&lang=js&external"
import style0 from "./email-template.scss?vue&type=style&index=0&id=310f64c3&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "310f64c3",
  null
  
)

export default component.exports