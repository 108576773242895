import SearchInput from "@/components/search-input";
import CrossIcon from "@/assets/icons/email-admin/icon-crossbt.svg";
import Search from "@/assets/icons/email-admin/icon-search.svg";
import TextTemplate from "@/assets/icons/email-admin/icon-text-temp.svg";
import FolderIcon from "@/assets/icons/email-admin/icon-folder.svg";

import AddBlue from "@/assets/icons/email-admin/icon-add-blue.svg";
import axios from "@/axios";

export default {
  name: "SearchModal",
  components: {
    SearchInput,
    Search,
    AddBlue,
    CrossIcon,
    TextTemplate,
    FolderIcon,
  },
  props: {
    open: Boolean,
    selectItem: Function,
    title: String,
  },
  emits: ["clicked"],
  data() {
    return {
      templates: [],
      rows: [],
      searchQuery: "",
    };
  },
  watch: {
    open: function (newVal) {
      if (newVal) {
        this.showModal();
      } else {
        this.hideModal();
      }
    },
  },
  computed: {
    filteredRows() {
      if (this.searchQuery) {
        return this.rows.filter((item) => {
          return item.name
            ?.toLowerCase()
            ?.includes(this.searchQuery.toLowerCase());
        });
      } else {
        return this.rows;
      }
    },
  },
  methods: {
    async showModal() {
      this.$refs.searchDiolog.showModal();
      await this.fetchTemplateList();
    },
    hideModal() {
      this.$refs.searchDiolog.close();
    },
    async fetchTemplateList() {
      try {
        const url = `/email-template/list?external_id=${this.$store.getters.getTenantId}`;
        const result = await axios.get(url);
        const data = this.flatten([],result.data.content);
        this.templates = data;
        this.rows = data;
      } catch (error) {
        console.error(error.message);
      }
    },
    flatten(into, node) {
      if (node == null) return into;
      if (Array.isArray(node)) return node.reduce(this.flatten, into);
      const { content, ...rest } = node;
      if (rest.type === "resource") into.push(rest);
      return this.flatten(into, content);
    },
    async openTemplate() {
      this.hideModal();
    },
  },

};
