import Add from "@/assets/icons/email-admin/icon-add-white.svg";
import DropdownIcon from "@/assets/icons/email-admin/icon-dropdown.svg";
import UpIcon from "@/assets/icons/email-admin/icon-up-white.svg";
import DownIcon from "@/assets/icons/email-admin/icon-down.svg";
import Dropdown from "@/components/dropdown";
import EmailDays from "../email-days";
import Sequence from "@/components/sequence";
import SearchModal from "@/components/search-modal";
import BrowseDir from "@/components/browse-dir";
import CustomInput from "@/components/custom-input";
import Search from "@/assets/icons/email-admin/icon-search.svg";
import renameFolderModal from "@/components/renameFolder";
import EmailTemplateModal from "@/components/emailtemplatemodal";
import tabs from "@/components/dashboard-components/tabs";
import tableLarge from "@/components/dashboard-components/table-large";
import { format } from "date-fns";
import EditGreen from "@/assets/icons/email-admin/icon-editgreen.svg";
import TrashRed from "@/assets/icons/email-admin/icon-trash-red.svg";
import FolderIcon from "@/assets/icons/email-admin/icon-folder.svg";
import GroupIcon from "@/assets/icons/email-admin/icon-group.svg";
import ArrowIcon from "@/assets/icons/email-admin/icon-arrow.svg";
import EditIcon from "@/assets/icons/email-admin/icon-edit.svg";
import Dialog from "@/components/dialog";
import axios from "@/axios";
import dropdown from '@/components/dropdown-base';
import sortIcon from '@/assets/icons/dashboard/dropdown-double.svg';
import { uniq } from "lodash";
import Loader from "@shared/loader";
import { checkPermission } from "@shared/utils/functions";
const modalConfirm = () => import("@/components/modal-confirm");

export default {
  name: "email-sequence",
  data() {
    return {
      isModalOpen: false,
      allSequences: [],
      rows: [],
      folderPath: [],
      format,
      isSequenceModalOpen: false,
      isResourceModalOpen: false,
      sequenceTemplates: [],
      isBrowseDirModalOpen: this.isDirModalOpen,
      isSequenceEditable: false,
      sequenceCreateMode: false,
      tobeReplaced: null,
      templateAddFlag: false,
      sequenceDetails: {
        id: "",
        templateName: "",
        description: "",
        path: "/",
        resource_type: "",
        sequences: [],
      },
      directoryToastMessage: null, 
      renameFolder: false,
      folderData: null, 
      errorMessage: '',
      filters: {
        name: [],
        description: [],
        created_at: [],
      },
      reserved:{},
      isLoading: false,
      sortParams: null
    };
  },
  components: {
    "test-table-large": tableLarge,
    BrowseDir,
    tabs,
    CustomInput,
    Loader,
    EmailTemplateModal,
    renameFolderModal,
    EmailDays,
    Sequence,
    modalConfirm,
    DownIcon,
    Search,
    Add,
    UpIcon,
    Dropdown,
    DropdownIcon,
    SearchModal,
    EditGreen,
    TrashRed,
    FolderIcon,
    GroupIcon,
    ArrowIcon,
    EditIcon,
    dropdown,
    sortIcon,
    "dialog-modal": Dialog,
  },
  props: {
    isDirModalOpen: Boolean,
    isSearchModal: Boolean,
    newModal:Boolean,
    onSearchModalClose: Function,
    onDirModalClose: Function,
    SearchModal: Function,
  },
  watch: {
    isSearchModal: function (val) {
      if (this.isResourceModalOpen !== val) {
        this.isResourceModalOpen = val;
        if (val) this.sequenceCreateMode = val
      }
    },
    isResourceModalOpen: function (val) {
      if (this.isSearchModal !== val) {
        this.$emit("onSearchModalClose");
      }
    },
    isDirModalOpen: function (val) {
      this.isBrowseDirModalOpen = val;
    },
    isBrowseDirModalOpen: function (val) {
      if (this.isDirModalOpen !== val) {
        this.$emit("onDirModalClose");
      }
      this.directoryToastMessage = null;
    },
    isSequenceModalOpen: function () {
      if(!this.isSequenceModalOpen){
        this.sequenceTemplates = [];
        this.sequenceCreateMode = false
      }
    } 
  },
  async mounted() {
    const storedFolderPath = localStorage.getItem('sequenceFolderPath');
    if (storedFolderPath) {
        this.folderPath = JSON.parse(storedFolderPath);
    }
    await this.fetchSequenceList();
  },
  methods: {
    checkPermission,
    async fetchSequenceList() {
      this.isLoading = true
      try {
        const url = `/email-sequence/list?external_id=${this.$store.getters.getTenantId}`;
        const result = await axios.get(url, { params: {...this.sortParams } });

        let data = result?.data?.content;
        this.allSequences = data;
      } catch (error) {
        this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
      }
      this.isLoading = false

    },
    changeFolderName() {
      const data = this.folderPath[this.folderPath.length - 1];
      this.fetchSequenceDetails(data);
    },
    async fetchSequenceDetails(row) {
      if (row?.type === "resource") {
        this.renameFolder = false;
        this.folderData = null;
        this.errorMessage = '';
        try {

          const res = await axios.get(`email-sequence/${row.id}`);
          this.sequenceDetails = {
            id: row.id,
            templateName: row.name,
            description: row.description,
            path: row.path,
            resource_type: row.resource_type,
            sequences: res.data,
          };

          const uniqueTemplateIds = Array.from(new Set(res.data.map(x => x.template_id)))
          const data = await Promise.all(uniqueTemplateIds.map((template_id) => axios.get(`email-template/${template_id}` )));
          const sequenceTemplates = res.data.map((x) => ({ 
            ...x, 
            ...data?.find((el) => el.data.id === x.template_id)?.data, 
          }));
          this.isSequenceEditable = row.system_default;
          this.sequenceTemplates = sequenceTemplates
        } catch (error) {
          this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
        }
        this.isSequenceModalOpen = true;
      } else if (row?.type === "directory") {
        this.renameFolder = true;
        this.folderData = row;
        this.folderData.templateName = row.name;
      }
    },
    async saveFolderName(formData) {
      this.errorMessage = '';
      if (!this.checkPermission('sequence.edit')) {
        this.$toast.error(`You don't have Permission to Rename Sequence`)
      }
      if (formData.templateName) {
        if (formData.templateName.includes("/")) {
          this.directoryToastMessage = { 
            type: "error",
            message: `Folder name can't have / `,
          };
          return ;
        }
        try {
          let path = `${formData?.path}/${formData.templateName}`.replace(
            "//",
            "/"
          );
          await axios.patch(
            `email-sequence/rename?sequence_id=${formData.id}&new_name=${formData?.templateName}&new_path=${path}`
          );
          
          this.renameFolder = false;
          this.$toast.success("Folder renamed successfully!");
        } catch (error) {
          this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
        }
        await this.fetchSequenceList();

        let data = this.folderPath[this.folderPath.length - 1];
        if (data.id === formData.id)
          this.folderPath[this.folderPath.length - 1] = { ...data, ...{
            name: formData.templateName,
            path: `${formData?.path}/${formData.templateName}`.replace(
              "//",
              "/"
            ),
          }};

        this.toggleModal();
      } else {
        this.errorMessage = 'Please enter a folder name';
      }
    },
    updateSequenceData(data) {
      // let index = this.sequenceTemplates.findIndex((el) => el.id === data.id);
      this.sequenceTemplates = data;
    },
    updateAllSequences(data) {
      this.sequenceDetails.sequences = data;
    },
    updateOnChaneSequenceData(data){
      this.sequenceTemplates = data;
    },
    async handleCreateFolder(data) {
      if (!this.checkPermission("sequence.create")){
        this.$toast.error("You don't have the permissions to create a folder !!");
        return
      }
      this.directoryToastMessage = null;
      try {
        if (data.name.includes("/")) {
          this.directoryToastMessage = { 
            type: "error",
            message: `Folder name can't have / `,
          };
          return ;
        }
        await axios.post(`email-sequence/create`, data);
        this.directoryToastMessage = {
          type: "success",
          message: "Folder created successfully!",
        };
        // this.$toast.success("Folder created successfully!");
      } catch (error) {
        this.directoryToastMessage = {
          type: "error",
          message: error?.response?.data?.detail || "Something went wrong. Please try again",
        };
        // this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
      }
      await this.fetchSequenceList();
    },
    async openSequenceModal(id) {
      this.SearchModal()
      this.isResourceModalOpen = true;
      this.isSequenceEditable = false;
      await this.fetchTemplateData(id);
      this.$refs["sequenceModal"].handleOutsideClick();
    },
    async fetchTemplateData(id) {
      try {
        const res = await axios.get(`email-template/${id}`);
        const sequenceData = res.data;
        if (this.tobeReplaced || this.tobeReplaced === 0) {
          if (this.templateAddFlag) {
            sequenceData.day = 0;
            const currentSeqData = {
              number: this.tobeReplaced+1,
              template_id: res?.data?.id,
              day: 0,
            };   
            this.sequenceTemplates.splice(this.tobeReplaced, 0, sequenceData);
            this.sequenceDetails.sequences.splice(this.tobeReplaced, 0, currentSeqData);
            this.sequenceDetails.sequences.map((el, index) => {
              if (index > this.tobeReplaced) el.number = el.number + 1;
              return el;
            })
          } else {
            sequenceData.day = this.sequenceTemplates[this.tobeReplaced].day;
            const currentSeqData = {
              id: this.sequenceTemplates[this.tobeReplaced]?.id,
              number: this.tobeReplaced+1,
              template_id: res?.data?.id,
              day: this.sequenceTemplates[this.tobeReplaced].day,
            };   
            this.sequenceTemplates.splice(this.tobeReplaced, 1, sequenceData);
            this.sequenceDetails.sequences.splice(this.tobeReplaced, 1, currentSeqData);
          }
        } else {
          const currentSeqData = {
            number: this.sequenceDetails.sequences?.length,
            template_id: res?.data?.id,
            day: 0,
          }; 
          this.sequenceTemplates = [...this.sequenceTemplates, ...[sequenceData]];  
          this.sequenceDetails.sequences = [...this.sequenceDetails.sequences, ...[currentSeqData]]; 
        } this.isSequenceModalOpen = true;
      } catch (error) {
        this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
      }
    },
    toggleModal() {
      this.renameFolder = false;
      this.folderData = null;
      this.errorMessage = '';
    },
    browseToggleModal() {
      this.clearSequenceDetails();
      this.isBrowseDirModalOpen = !this.isBrowseDirModalOpen;
    },
    clearSequenceDetails() {
      this.sequenceDetails = {
        id: "",
        templateName: "",
        description: "",
        path: "/",
        resource_type: "",
        sequences: [],
      };
    },
    showDetails() {
      this.showModal = true;
    },
    handlePlusClick(index, addFlag) {
      this.isResourceModalOpen = !this.isResourceModalOpen;
      this.templateAddFlag = addFlag;
      this.tobeReplaced = typeof(index) === "number" && index >=0 ? index: null;
    },
    handleBreadcrumbsPath(index) {
      this.folderPath.splice(index+1);
      localStorage.setItem('sequenceFolderPath', JSON.stringify(this.folderPath));
    },
    handlePath(props) {
      const { ...rest } = props.row;
      if (rest.type === "directory") {
        this.folderPath.push({ ...rest, index: props.index });
        localStorage.setItem('sequenceFolderPath', JSON.stringify(this.folderPath));
        this.reserved = this.filters;
        this.filters ={
          name: [],
          description: [],
          created_at: []};
      }
    },
    backPath() {
      this.folderPath.pop();
      localStorage.setItem('sequenceFolderPath', JSON.stringify(this.folderPath));
    },
    editclick() {
    },
    async deleteclick(id) {
      const promise = await this.$refs["confirm-popup"].show({
        title: "Are you sure?",
        message: "This Sequence will be deleted permanently. Please confirm to continue deleting this sequence.",
    });
      try {
        if (promise){
          await axios.delete(`email-sequence/${id}`);
        this.$toast.success("Sequence deleted successfully!");
        }
      } catch (error) {
        this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
      }
      this.$refs["confirm-popup"].close();
      await this.fetchSequenceList();
    },
    sequenceModal() {
      this.isSequenceModalOpen = !this.isSequenceModalOpen;
      this.renameFolder = false;
      this.folderData = null;
      this.errorMessage = '';
    },
    formatFilterValue(valuesArray) {
      return valuesArray.map((value) => value.id).join(',');
    },
    updateFilters(column, value) {
      if (this.filters?.[column.field]) this.filters[column.field] = value;
    },
    async onSortChange(data) {
      if (data && data.length && data[0].type !== 'none') {
          data = data[0]
          this.sortParams = {
          sort: data.field + ':' + data.type
          },
          await this.fetchSequenceList()
      }else{
          this.sortParams = null
          await this.fetchSequenceList()
      }

    },
  },
  computed: {
    columns() {
      return [
        {
          label: "Name",
          field: "name",
          width: "50%",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: this.currentPathSequences
              ?.map((x) => x.name)
              .filter((x) => x),
            formatValue: this.formatFilterValue
          },
        },
        {
          label: "Description",
          field: "description",
          width: "30%",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: this.currentPathSequences
              ?.map(x => x.description)
              .filter(x => x),
            formatValue: this.formatFilterValue
          },
        },
        {
          label: "Date",
          field: "created_at",
          width: "10%",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: uniq(this.currentPathSequences?.map(x => {
              if (x?.created_at) {
                return format(new Date(x?.created_at), 'dd MMM yyyy');
              }
              return ''
            }).filter((x) => x)),
            formatValue: this.formatFilterValue
          },
        },
        {
          label: "",
          field: "actions",
          width: "10%",
        },
      ];
    },
    currentPathSequences() {
      let result = this.allSequences;
      for (let i = 0; i < this.folderPath.length; i++) {
        let requiredPath = result.find(pth => pth.id == this.folderPath[i].id)
        result = requiredPath?.content || []
    }
      return result;
    },
    emailSequences() {
      let result = this.currentPathSequences;
      for (let filter in this.filters) {
        if (this.filters[filter]?.length) {
          if (filter === 'created_at') {
            result = result.filter((el) => {
              const date = el[filter] ? format(new Date(el[filter]), "dd MMM yyyy"): "";
              return this.filters[filter].includes(date)
            });
          } else
            result = result.filter((el) => this.filters[filter].includes(el[filter]));
        }
      }
      this.rows = result?.map((x) => ({
        ...x,
        created_at: x?.created_at ? format(new Date(x?.created_at), "dd MMM yyyy") : '',
      }));
      return this.rows;
    }
  },
};
