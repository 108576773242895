var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-large overflow-x-auto relative",style:(_vm.cssVars)},[_c('vue-good-table',_vm._b({ref:"table",attrs:{"mode":"remote","sort-options":{enabled: false},"totalRows":_vm.totalRows,"isLoading":_vm.isLoading,"pagination-options":_vm.paginationOptions,"rows":_vm.rows,"columns":_vm.computedColumns,"styleClass":"vgt-table bordered vgt-table-large"},on:{"on-page-change":function($event){return _vm.$emit('page-change', $event)},"sort-change":function($event){return _vm.$emit('sort-change', $event)},"on-per-page-change":function($event){return _vm.$emit('per-page-change', $event)},"on-column-filter":_vm.onColumnFilter},scopedSlots:_vm._u([{key:"column-filter",fn:function({ column, updateFilters }){return [(column && column.config && column.config.filter)?_c('dropdown',{ref:_vm.setFilterRef,staticClass:"filter-dropdown",attrs:{"reposition":"","searchable":"","placeholder":"Select","options":column.filterOptions.filterDropdownItems || null,"colors":{
                text: '#282828',
                bg: 'white',
                svg: '#282828',
                border: 'transparent',
            },"value":_vm.isFilters ? _vm.filters[column.field] : null,"limit":1,"minWidth":"102px","config":{ label: 'name', trackBy: 'id' },"loading":column.config.isLoading},on:{"input":(value) => _vm.updateColumnFilters(column, value, updateFilters),"search":function($event){return _vm.searchFilter(column, $event)}},scopedSlots:_vm._u([{key:"caret",fn:function(){return [_c('sort-icon')]},proxy:true}],null,true)}):_c('div')]}},{key:"table-column",fn:function(props){return [(props.column.config
                    && props.column.config.icon)?_c('span',{staticClass:"th-col th-col--icon"},[_c('img',{staticClass:"icon",attrs:{"src":props.column.config
                        && props.column.config.icon}}),_vm._v(" "+_vm._s(props.column.label)+" ")]):_c('span',{staticClass:"th-col"},[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.config && props.column.config.type == 'link' )?_c('span',{staticClass:"text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron",on:{"click":function($event){return _vm.handleViewCase(props.row)}}},[_vm._v(_vm._s(props.formattedRow[props.column.field]))]):(props.column.config
                    && props.column.config.type == 'status')?_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$emit('handleStatusClick',props)}}},[_c('status',{attrs:{"value":props.formattedRow[props.column.field]}})],1):(props.column.config && props.column.config.type == 'clone')?_c('div',{staticClass:"flex justify-center"},[_c('Clone',{attrs:{"row":props.formattedRow},on:{"handleCloneField":function($event){return _vm.$emit('handleCloneField', $event)}}})],1):(props.column.config
                    && props.column.config.type == 'indicators'
                    && props.formattedRow[props.column.field])?_c('indicators',{attrs:{"value":props.formattedRow[props.column.field]}}):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"emptystate",fn:function(){return [_c('div',{staticClass:"alert alert-info my-5"},[_vm._v(" No Data Found ")])]},proxy:true}])},'vue-good-table',_vm.config,false)),(_vm.isFilters)?_c('button',{staticClass:"clear-filters cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute top-2 left-2",on:{"click":_vm.clearFilters}},[_vm._v(" Clear filters ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }